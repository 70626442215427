<template>
    <div class="main">
        <div >

            <div v-if="login" class="header_ydl">
                <div class="mcsty">{{nsrmc}}</div>
                <div class="btnsty">
                    <van-button size="small" style="" round  plain hairline  type="danger" @click="QHClick()">切换用户</van-button>
                </div>
            </div>
            <div v-else class="header_wdl ">
                <div class="mcsty"></div>
                <div class="btnsty" style="margin-top:40px;">
                    <van-button size="small" round plain hairline  type="primary" @click="DLClick()">点击登录</van-button>
                </div>
            </div>
            
        </div>
        <div  class="" style="padding:0;"> 
            <van-row gutter="20">
                <van-col span="12" class="fpkjbj" style="padding:0;" @click="itemClick(gnArr[0])">
                    <div class="fpsty">发票开具</div>
                    <div class="smsty">方便快捷开具各种发票</div>
                </van-col>
                <van-col span="12" class="fpcxbj" style="padding:0;" @click="itemClick(gnArr[1])">
                    <div class="fpsty">发票查询</div>
                    <div class="smsty">各种发票类型一键查询</div>
                </van-col>
            </van-row>

        </div>
        <div class="" style="border:8px solid #F6F7F9;">
            <!-- <van-cell-group> -->
                <van-grid :column-num="2" center :icon-size="32">
                    <van-grid-item  :icon="require('@/assets/img/spxx.png')" text="商品信息"
                        @click="itemClick(gnArr[2])" />
                    <van-grid-item  :icon="require('@/assets/img/khxx.png')" text="客户信息"
                        @click="itemClick(gnArr[3])"/>
                </van-grid> 
            <!-- </van-cell-group> -->
            
        </div>
        <div class="equipment">
            <van-cell-group inset style="margin:0;box-shadow:4px 4px 10px #eee; border:1px solid #eee;">
                <template #title>
                    <div style="display: flex; flex-direction: row; align-content: center">
                        <!-- <div style="width: 10px; height: 20px; background: #307bf7; margin-right: 4px"></div> -->
                        <div style="line-height: 20px;font-weight:bold;color:#333;margin-left:-20px;">设备信息</div>
                    </div>
                </template>
                <!-- <van-tabs v-model="fplx_active" :ellipsis="false" color="#1989fa" title-active-color="#1989fa">
                    <van-tab v-for="(item, index) in fplxArr" :key=index :title=item> -->
                        <van-cell center>
                            <!-- 使用 title 插槽来自定义标题 -->
                            <template #title>
                                <div class="titleContent">
                                    <div class="qjListItem">
                                        <span class="spanTitle">设备状态：</span>
                                        <span style="color: red"  v-if="sbztData.sbzt=='离线'">{{sbztData.sbzt}}</span>
                                        <span style="color: green" v-else>{{sbztData.sbzt}}</span>
                                    </div>
                                    <div class="qjListItem">
                                        <span class="spanTitle">税控装置编码：</span>
                                        <span>{{sbztData.UDiskNum}}</span>
                                    </div>
                                    <div class="qjListItem">
                                        <span class="spanTitle">税控装置类型：</span>
                                        <span v-if="sbztData.UDiskType=='1'">税控盘</span>
                                        <span v-else-if="sbztData.UDiskType=='2'">税控报税盘</span>
                                        <span v-else-if="sbztData.UDiskType=='3'">金税盘</span>
                                        <span v-else-if="sbztData.UDiskType=='4'">金税报税盘</span>
                                        <span v-else-if="sbztData.UDiskType=='10'">税务UKEY</span>
                                    </div>
                                    <div class="qjListItem">
                                        <span class="spanTitle">授权状态：</span>
                                        <span style="color: green" v-if="sbztData.LicenseState=='1'">当前授权有效</span>
                                        <span style="color: red" v-else>未授权或已过期</span>
                                    </div>
                                    <div class="qjListItem">
                                        <span class="spanTitle">是否已初始化：</span>
                                        <span v-if="sbztData.IsInitSucceeded=='1'">已完成初始化</span>
                                        <span v-else>未初始化</span>
                                    </div>
                                    <div class="qjListItem">
                                        <span class="spanTitle">末次在线时间：</span>
                                        <span>{{sbztData.LatestOnlineTime}}</span>
                                    </div>
                                    <div class="qjListItem">
                                        <span class="spanTitle">税务机关名称：</span>
                                        <span style="text-align:right;">{{sbztData.TaxOfficeName}}</span>
                                    </div>
                                </div>
                            </template>
                        </van-cell>
                    <!-- </van-tab>
                </van-tabs> -->
            </van-cell-group>
            <van-cell-group inset style="margin:0;box-shadow:4px 4px 10px #eee; border:1px solid #eee;">
                <template #title>
                    <div style="display: flex; flex-direction: row; align-content: center">
                        <!-- <div style="width: 10px; height: 20px; background: #307bf7; margin-right: 4px"></div> -->
                        <div style="line-height: 20px;font-weight:bold;color:#333;margin-left:-20px;">发票库存</div>
                    </div>
                </template>
                <van-cell center>
                    <template #title>
                        <div class="qjListItem">
                            <div class="divTitle divbold" style="width:160px;">发票种类</div>
                            <div class="divTitle divbold">剩余量</div>
                        </div>
                    </template>
                </van-cell>
                <van-cell center v-for="(item,index) in sbztData.invoiceSections" :key="index">
                    <template #title>
                        <div class="qjListItem">
                            <div class="divTitle" v-if="item.InvoiceType == '1'">增值税专用发票</div>
                            <div class="divTitle" v-else-if="item.InvoiceType == '2'">增值税普通发票（纸票）</div>
                            <div class="divTitle" v-else-if="item.InvoiceType == '3'">增值税普通发票（卷式）</div>
                            <div class="divTitle" v-else-if="item.InvoiceType == '4'">增值税电子普通发票</div>
                            <div class="divTitle" v-else-if="item.InvoiceType == '8'">增值税电子专用发票</div>
                            <div class="divTitle">{{item.RemainingQuantity}}</div>
                        </div>
                    </template>
                </van-cell>
               
            </van-cell-group>
        </div>
        <div class="chart" style="min-height:80px;">
        </div>

        <van-popup v-model:show="qyxxshow" :style="{ width: '90%'  }"  >
            <van-cell-group>
                <van-cell center v-for="item in nsrArr" :key = item :title="item.merchant_name" @click="xzClick(item)">
                    <template #right-icon>
                        <van-icon v-if= "item.mryh === item.merchant_id" name="passed"/>
                    </template>
                </van-cell>
            </van-cell-group>
        </van-popup>
    </div>
    <Footer></Footer>
</template>

<script>
    import Footer from "../../components/Tabbar/index.vue";
    import getUserInfo from '@/mixin/getUserInfo.js'
    import {defineComponent, ref, getCurrentInstance, computed } from 'vue'
    import {useStore} from 'vuex';
    import loginRequest from '../../api/login'


    export default defineComponent({
        name: "home",
        components: {
            Footer
        },
        setup() {
            const fplx_active = ref(0);
            const qyxxshow = ref(false);
            const gnArr = ref([
                {
                    title: "发票开具",
                    path: "/invoicing"
                },
                {
                    title: "发票查询",
                    path: "/invoiceQuery"
                },
                {
                    title: "商品信息",
                    path: "/commodity"
                },
                {
                    title: "客户信息",
                    path: "/custom"
                },
            ]);

            const fplxArr = ref(["增值税专用发票（纸质）", "增值税普通发票（纸质）", "增值税电子普通发票"]);
            const {proxy} = getCurrentInstance();
            const itemClick = (item) => {
                // console.log(item);
                sessionStorage.removeItem("fpkj-sdkp-fptt");
                sessionStorage.removeItem("fpkj-sdkp-addmx");
                sessionStorage.removeItem("fpkj-sdkp-mynub");
                sessionStorage.removeItem("fpkj-sdkp-fplx");
                sessionStorage.removeItem("spxx-add-benyexx");
                sessionStorage.removeItem("isAdd");
                
                console.log(sbztData.value.invoiceSections);
                if(sbztData.value.invoiceSections!=undefined){
                    let arr = [];
                    for(var i=0;i<sbztData.value.invoiceSections.length;i++){
                        var obj = {};
                        obj.type = sbztData.value.invoiceSections[i].InvoiceType;
                        if(obj.type=="1"){
                            obj.val='增值税专用发票';
                        }else if(obj.type=="2"){
                            obj.val='增值税普通发票（纸票）';
                        }else if(obj.type=="3"){
                            obj.val='增值税普通发票（卷式）';
                        }else if(obj.type=="4"){
                            obj.val='增值税电子普通发票';
                        }else if(obj.type=="8"){
                            obj.val='增值税电子专用发票';
                        }
                        arr.push(obj.val);
                    }
                    console.log(arr);
                    sessionStorage.setItem('sy-fpzl-set',JSON.stringify(arr));
                }
                
                proxy.$root.$router.push({path: item.path});
            };
            const DLClick = () => {
                proxy.$root.$router.push({path: "/login", params: {"fp": 1}});
            };
            const store = useStore();
            const nsrmc = computed(() => store.state.nsrUserModel.merchant_name);
            const login = computed(() => nsrmc.value.length > 0? true: false);
            console.log(nsrmc.value.length)
            console.log(login)
            const nsrArr = computed(() => store.state.nsrUserArr);
            const QHClick = ()=>{
                qyxxshow.value = true;
            };
            const xzClick = (item)=>{
                if (item.merchant_id === item.mryh) {
                    return;
                }
                loginRequest.wx_user_modify_mryh({mryh: item.merchant_id}).then(res=>{
                    if (res.returnCode === "00"){
                        qyxxshow.value = false;
                        getUserInfo(proxy.$root.$route, store);
                    }
                });
            };
            //获取设备信息数据
            const sbztData = computed(() => store.state.sbztxx);
            // console.log(sbztData);
            
            return {
                fplx_active,
                gnArr,
                fplxArr,
                login,
                nsrmc,
                qyxxshow,
                nsrArr,
                itemClick,
                DLClick,
                QHClick,
                xzClick,
                sbztData
            };
        }
    });
</script>

<style scoped lang="less">
    .main {
        /* background:#eee; */
        background-image: url('../../assets/img/sybj.png');
        background-repeat:  no-repeat;
        background-size:100% 18%;
        margin: 0;
        padding:50px 10px 10px;
    }

    .header {
        /* margin:  8px ; */
        /* border: #eee solid 1px; */
        /* box-shadow: 0px 10px 5px -10px #000; */
        /* border-radius: 8px; */
        width: 100%;
        height: 150px;
        background-image:  url('../../assets/img/sy-wdl-banner.png');
        background-size:100% 100%;
        z-index: 20;
    }

    .header_icon {
        width: 60px;
    }

    .header_title {
        margin-left: 20px;
        font-weight: bold;
    }

    .qjListItem {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
    }

    .spanTitle {
        min-width: 110px;
    }

    .divTitle {
        /* flex: 1; */
        text-align: center;
    }

    .divbold {
        font-weight: bold;
        color: #1989fa;
    }
    .header_wdl{
        background-image:  url('../../assets/img/sy-wdl-banner.png');
        background-size:100% 100%;
        height: 150px;
        width: 100%;
        color: #fff;
    }
    .header_ydl{
        background-image: url('../../assets/img/sy-ydl-banner.png');
        background-size:100% 100%;
        height: 150px;
        width: 100%;
        color: #fff;
    }
    .mcsty{
        width: 48%;
        font-size: 16px;
        font-weight: bold;
        padding:23px 25px 15px 25px;
    }
    .btnsty{
        padding-left: 25px;
    }
    .fpcxbj{
        flex: 1;
        background-image:  url('../../assets/img/fpcx.png');
        background-size:100% 100%;
        min-height: 100px;
        width: 100%;
        color: #fff;
    }
    .fpkjbj{
        flex: 1;
        background-image:  url('../../assets/img/fpkj.png');
        background-size:100% 100%;
        min-height: 100px;
        width: 100%;
        color: #fff;
    }
    .fpsty{
        font-size: 16px;
        font-weight: bold;
        padding:15px 10%;
    }
    .smsty{
        font-size: 12px;
        padding-left: 10%;
    }
    /deep/.van-grid-item .van-grid-item__content {
        padding: 8px;
    }
    /deep/.van-grid-item .van-grid-item__text{
        font-size:14px;
        margin: 0;
    }
</style>